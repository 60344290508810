import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Services from '../components/services/services'
import CardList from '../components/card/cardList'
import Clients from '../components/clients/clients'
import Section from '../components/layout/section';
import { useState } from 'react';
import HeroImage from '../components/hero/heroImage';

const KundenLeistungen = (props) => {
  const siteTitle = get(this, 'props.data.site.siteMetadata.title')
  const data = props.data.contentfulKundenLeistungen;

  let posts = props.data.allContentfulArtikel.edges;
  const leistungen = props.data.allContentfulLeistung.edges.map(it=>it.node);
  const [filterTopic, setFilterTopic] = useState(leistungen[0]);

  return (
    <div>
      <Helmet title={siteTitle} />

      <div className="wrapper">
        <Section>            
          <Services onChange={(topic) => setFilterTopic(topic)} hideTitle={true} navPosition={'bottom'} />
          { filterTopic &&
          <CardList filterTopic={filterTopic} allTopics={leistungen} items={posts.map(it=>it.node)} />
        }
        </Section>
        <Section id="kunden" small>
          <Clients />
        </Section>

        

        {/*<h2 className="section-headline">Recent articles</h2>
        <ul className="article-list">
          {posts.map(({ node }) => {
            return (
              <li key={node.slug}>
                <ArticlePreview article={node} />
              </li>
            )
          })}
        </ul>*/}
        {data.image &&<HeroImage image={data.image} title={''} />}
      </div>
    </div>);
}
 

export default KundenLeistungen

export const pageQuery = graphql`
  query BlogIndexQuery {
    contentfulKundenLeistungen {
      title
      image {
        fluid(maxWidth: 1180,quality: 90,  background: "rgb:000000") {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allContentfulLeistung (sort: {order: ASC, fields: createdAt}){
      edges {
          node {
          slug
        }
      }
    }
    allContentfulArtikel(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            tags
            leistung
            highlight
            category {
              title
              id
              slug
            }
            template:produkt
            kunde {
              name
              id
            }
            image:heroImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              childMarkdownRemark {
                html,
                excerpt
              }
            }
            body {
              childMarkdownRemark {
                html,
                excerpt
              }
            }
            kunde {
              name
              url
              body {
                childMarkdownRemark {
                  html,
                  excerpt
                }
              }
              logo {
                fluid(maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
                  ...GatsbyContentfulFluid_withWebp
                }
                file {
                  fileName
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              artikel {
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
                tags
                leistung
                template:produkt
                kunde {
                  name
                  id
                }
                image:heroImage {
                  fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                description {
                  childMarkdownRemark {
                    html,
                    excerpt
                  }
                }
              }
            }
        }
      }
    }
  }
`
